import * as React from 'react'

import StyledInputContainer from '../common/input-container'
import StyledLabelContainer from '../common/label-container'
import StyledLabel from '../label/styled'
import Checkbox from '../checkbox'

import StyledInput, { StyledDesc } from './styled'

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  wrapperClassName?: string
  name?: string
  label?: string
  checkboxLabel?: string
  checkboxSizeXY?: number
  checkboxName?: string
  onCheckboxChange?: (el: React.ChangeEvent<HTMLInputElement>) => void
  prefix?: string
  prefixNode?: React.ReactNode
  suffix?: React.ReactNode
  onChange?: (el: React.ChangeEvent<HTMLInputElement>) => void
  onEnterKey?: (el: React.KeyboardEvent<HTMLInputElement>) => void
  desc?: string
  emptyPlaceholder?: string
}

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    name,
    prefix,
    prefixNode,
    suffix,
    onChange,
    wrapperClassName,
    desc,
    emptyPlaceholder,
    onEnterKey,
    ...restProps
  } = props
  const { checkboxLabel, checkboxSizeXY, checkboxName, onCheckboxChange, ...inputProps } = restProps
  const { onFocus, onBlur, placeholder } = inputProps
  const handlChange = React.useCallback(
    (el: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(el)
    },
    [onChange],
  )
  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (typeof emptyPlaceholder === 'string') {
        e.target.placeholder = emptyPlaceholder
      }
      onFocus && onFocus(e)
    },
    [emptyPlaceholder, onFocus],
  )
  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.target.placeholder = placeholder || ''
      onBlur && onBlur(e)
    },
    [placeholder, onBlur],
  )
  const handleKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (onEnterKey && e.currentTarget.type === 'text' && e.key === 'Enter') {
        e.preventDefault()
        onEnterKey(e)
      }
    },
    [onEnterKey],
  )

  return (
    <>
      {(label || checkboxName) && (
        <StyledLabelContainer>
          {label && <StyledLabel htmlFor={name}>{label}</StyledLabel>}
          {checkboxName && (
            <div>
              <Checkbox
                label={checkboxLabel || ''}
                sizeXY={checkboxSizeXY}
                name={checkboxName}
                onChange={onCheckboxChange}
              />
            </div>
          )}
        </StyledLabelContainer>
      )}
      <StyledInputContainer className={wrapperClassName}>
        <StyledInput className="--flex-x-y-center container">
          {prefix && <span className="prefix">{prefix}</span>}
          {prefixNode && <span className="prefix">{prefixNode}</span>}
          <input
            ref={ref}
            name={name}
            type="text"
            onChange={handlChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            // onKeyPress: onKeyPress는 deprecated 상태지만 한글 입력 후 enter 가 두번 트리거되는 현상 때문에 사용(맥북환경에서 onKeyUp 등등..)
            onKeyPress={handleKeyPress}
            {...inputProps}
          />
          {suffix}
        </StyledInput>
      </StyledInputContainer>
      {desc && <StyledDesc className="--text-low-level desc">{desc}</StyledDesc>}
    </>
  )
})

Input.displayName = 'Input'

export default Input
